import React from "react";
import { format } from "date-fns";

import ThemeContext from "../context/ThemeContext";
import { cn } from "../lib/helpers";
import * as styles from "./exhibition-info.module.css";

String.prototype.replaceLast = function (search, replace) {
  return this.replace(new RegExp(search+"([^"+search+"]*)$"), replace+"$1");
}

const ExhibitionInfo = props => {
  const { startedAt, endedAt, supportedBy } = props;
  const displayDates = startedAt && endedAt;
  const organisationLogoUrls = supportedBy.map(org => org.logo.asset.url);

  const organisationNames = supportedBy.map(org => {
    if (org.name.includes('Victoria')) {
      return 'the V&A';
    }

    return org.name;
  });

  return (
    <ThemeContext.Consumer>
      {theme => (
        <ul className={styles.root}>
          {displayDates && (
            <li>{format(new Date(startedAt), "D MMMM YYYY")} – {format(new Date(endedAt), "D MMMM YYYY")}</li>
          )}
          {supportedBy && (
            <li>In partnership with {organisationNames.join(', ').replaceLast(',', ' and ')}</li>
          )}
          {organisationLogoUrls && (
            <li className={cn(styles.logos, theme.dark ? styles.dark : '')}>
              {organisationLogoUrls.map((url, index) => (
                <img src={url} alt={`${organisationNames[index]} logo`} />
              ))}
            </li>
          )}
        </ul>
      )}
    </ThemeContext.Consumer>
  );
};

export default ExhibitionInfo;