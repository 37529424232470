import React from 'react';
import ReactPlayer from 'react-player/youtube';

import * as styles from './youtube-player.module.css';

const YouTubePlayer = ({ url }) => {
  if (!url) {
    return null
  }

  return (
    <div className={styles.root}>
      <ReactPlayer 
        className={styles.player} 
        url={url} 
        width="100%"
        height="100%"
        controls={true}
      />
    </div>
  );
};

export default YouTubePlayer;