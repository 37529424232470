import React from "react";
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery } from 'react-photoswipe-gallery';
import PageAnchorSection from "./pageAnchorSection";
import slugify from 'react-slugify';
import BlockText from "./block-text";
import Container from "./container";
import { GallleryItem } from "./gallery-item";

import * as styles from "./image-gallery-section.module.css";

const ImageGallerySection = ({
  text,
  title,
  setActiveSection,
  images
}) => {
  const sectionID = slugify(title);
  const options = {
    captionEl: false,
    fullscreenEl: false,
    zoomEl: false,
    shareEl: false,
    preloaderEl: false,
    counterEl: false,
    maxSpreadZoom: 1,
    clickToCloseNonZoomable: true,
    getDoubleTapZoom: (isMouseClick, item) => {
      return item.initialZoomLevel;
    },
    bgOpacity: 1,
    timeToIdle: 4000,
    timeToIdleOutside: 2000
  }

  return (
    <PageAnchorSection id={sectionID} className={styles.root} setActiveSection={setActiveSection}>
      <Container>
        <h4>{title}</h4>
        {text && <BlockText blocks={text} />}
      </Container>

      <div className={styles.imageGrid}>
        <Gallery options={options}>
          {images.map(node => <GallleryItem key={node._key} node={node} />)}
        </Gallery>
      </div>
    </PageAnchorSection>
  );
};

export default ImageGallerySection;