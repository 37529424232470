import React from "react";
import slugify from 'react-slugify';
import BlockText from "./block-text";
import Container from "./container";
import Carousel from "./carousel";
import CarouselFigure from "./carousel-figure";
import PageAnchorSection from "./pageAnchorSection";
import VideoEmbed from "./video-embed";

const ImageCarouselSection = ({
  text,
  images,
  title,
  setActiveSection,
  hasPageAnchorWrapper = true
}) => {
  const sectionID = slugify(title);

  if (hasPageAnchorWrapper) {
    return (
      <PageAnchorSection id={sectionID} setActiveSection={setActiveSection}>
        <Container>
          <h4>{title}</h4>
          <BlockText blocks={text} />
        </Container>
  
        <Carousel items={images}>
          {images.map((item) => {
            if (item._type == 'figure') {
              return <CarouselFigure image={item} />
            } else {
              return <VideoEmbed node={item} contained={false} />
            }
          })}
        </Carousel>
      </PageAnchorSection>
    );
  } else {
    return (
      <div style={{marginBottom: '2.1rem'}}>
        <Container>
          <h4>{title}</h4>
          <BlockText blocks={text} />
        </Container>
  
        <Carousel>
          {images.map((item) => {
            if (item._type == 'figure') {
              return <CarouselFigure image={item} />
            } else {
              return <VideoEmbed node={item} contained={false} />
            }
          })}
        </Carousel>
      </div>
    );
  }
};

export default ImageCarouselSection;