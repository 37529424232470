import React, { useRef, useEffect } from "react";

const PageAnchorSection = ({ children, id, setActiveSection }) => {
  const ref = useRef();
 
  // mount 
  useEffect(() => {
    const cachedRef = ref.current;

    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.isIntersecting) {
          setActiveSection(cachedRef.id);
        }
      });

    observer.observe(cachedRef);

    // unmount
    return function() {
      observer.unobserve(cachedRef);
    }
  }, []);

  return (
    <section id={id} ref={ref}>
      {children}
    </section>
  );
};

export default PageAnchorSection;
