import React from "react";
import PageAnchorSection from "./pageAnchorSection";
import slugify from 'react-slugify';
import BlockText from "./block-text";
import Container from "./container";
import YouTubePlayer from "./youtube-player";
import VimeoPlayer from "./vimeo-player";
import * as styles from "./video-embed-section.module.css";

const maybeVimeoEmbed = (url) => {
  let player = null;

  if (url.includes('vimeo.com')) {
    player = <VimeoPlayer url={url} />;
  } else {
    player = <YouTubePlayer url={url} />;
  }

  return player;
}

const VideoEmbedSection = ({
  text,
  videoEmbedUrl,
  title,
  setActiveSection
}) => {
  const player = maybeVimeoEmbed(videoEmbedUrl.url);
  const sectionID = slugify(title);

  return (
    <PageAnchorSection id={sectionID} className={styles.root} setActiveSection={setActiveSection}>
      <Container>
        <h4>{title}</h4>
        <BlockText blocks={text} />
      </Container>
      <div className={styles.embed}>{player}</div>
    </PageAnchorSection>
  );
};

export default VideoEmbedSection;