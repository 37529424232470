import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { Item } from 'react-photoswipe-gallery';
import clientConfig from "../../client-config";

import * as styles from "./gallery-item.module.css";

export function GallleryItem({ node }) {
  if (!node.asset) {
    return null;
  }

  const thumbnailImageData = getGatsbyImageData(node.asset, { maxWidth: 500 }, clientConfig.sanity);
  const largeImageData = getGatsbyImageData(node.asset, { maxWidth: 1440 }, clientConfig.sanity);

  return (
    <Item
      original={largeImageData.images.fallback.src}
      thumbnail={thumbnailImageData.images.fallback.src}
      width={largeImageData.width / 2}
      height={largeImageData.height / 2}
    >
      {({ ref, open }) => {
        return (
          <div ref={ref} onClick={open} className={styles.root}>
            <GatsbyImage image={thumbnailImageData} alt={node.alt} />
          </div>
        );
      }}
    </Item>
  );
}
