import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import * as styles from "./carousel-figure.module.css";

const CarouselFigure = ({ image, width = 1200 }) => {
  if (!image.asset) {
    return null;
  }

  return (
    <figure className={styles.root}>
      <img
        src={imageUrlFor(buildImageObj(image))
          .width(width)
          .height(Math.floor((9 / 16) * width))
          .fit("clip")
          .url()}
        alt={image.alt}
        lazy="true"
      />
    </figure>
  );
}

export default CarouselFigure;