import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cn } from "../lib/helpers";

import * as styles from "./carousel.module.css";

class Carousel extends React.Component {
  state = {
    activeSlide: 0,
    caption: this.props.children[0]?.props.image?.caption || this.props.children[0]?.props.node?.caption || ""
  };

  next = () => {
    this.slider.slickNext();
  }

  prev = () => {
    this.slider.slickPrev();
  }

  render() {
    const { children } = this.props;
    const totalSlides = children.length;
    const settings = {
      dots: false,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      beforeChange: (current, next) => {
        let caption = "";

        if (children[next].props.image?.caption) {
          caption = children[next].props.image?.caption;
        } else if (children[next].props.node?.caption) {
          caption = children[next].props.node?.caption;
        }

        this.setState({ activeSlide: next, caption });
      }
    };
    
    return (
      <div className={styles.root}>
        <div className={styles.slider}>
          <div className={cn(styles.images, 'slick-slider-images')}>
            <Slider ref={c => (this.slider = c)} {...settings}>
              { children }
            </Slider>
          </div>
        </div>
        <div className={styles.text}>
          <div className={styles.caption}>
            <p>{this.state.caption}</p>
          </div>
          <div className={styles.fraction}>
            {`${this.state.activeSlide + 1}/${totalSlides}`}
          </div>
        </div>
      </div>
    );
  }
};


export default Carousel;