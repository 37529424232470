import React, { useContext, useState } from "react";
import slugify from 'react-slugify';
import Sticky from 'react-stickynode';
import ScrollContext from "../context/ScrollContext";
import { cn } from "../lib/helpers";

import * as styles from "./section-menu.module.css";

const getOffsetTop = element => {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

const onClick = (id) => {
  const target = document.getElementById(id);

  window.scroll({
    behavior: 'smooth',
    top: getOffsetTop(target)
  });
}

const SectionMenu = ({ items, activeSection, hasSelectedArtworks }) => {
  const scroll = useContext(ScrollContext);
  const [stickyStatus, setStickyStatus] = useState(0);

  const handleStateChange = (status) => {
    setStickyStatus(status.status)
  };

  return (
    <Sticky enabled={true} top={0} onStateChange={handleStateChange}>
      <ul className={cn(styles.root, scroll.scrollDir === 'scrolling up' && stickyStatus === Sticky.STATUS_FIXED ? styles.scrollingUp : '')}>
        {items.map(({ title }) => {
          const id = slugify(title);
          return (
            <li key={id} onClick={() => onClick(id)} className={cn(styles.listItem, activeSection == id ? styles.isSelected : '')}>
              {title}
            </li>
          );
        })}
        {hasSelectedArtworks && 
          <li onClick={() => onClick('selected-artworks')} className={cn(styles.listItem, activeSection == 'selected-artworks' ? styles.isSelected : '')}>
            Selected Artworks
          </li>
        }
      </ul>
    </Sticky>
  )
};

export default SectionMenu;