import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Exhibition from "../components/exhibition";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query ExhibitionTemplateQuery($id: String!) {
    exhibition: sanityExhibition(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      virtualGallery
      startedAt
      endedAt
      _rawSections
      _rawSelectedArtworks
      _rawIntroduction
      supportedBy {
        name
        logo {
          asset {
            url
          }
        }
      }
      artists {
        name
        _rawBio
      }
      featuredMedia {
        largeImage {
          alt
          asset {
            _id
          }
          crop {
            _key
            _type
            bottom
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
        smallImage {
          alt
          asset {
            _id
          }
          crop {
            _key
            _type
            bottom
            left
            right
            top
          }
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
        }
        videoUrlHD
        videoUrlSD
      }
    }
  }
`;

const ExhibitionTemplate = props => {
  const { data, errors } = props;
  const exhibition = data && data.exhibition;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {exhibition && <SEO title={exhibition.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {exhibition && <Exhibition {...exhibition} />}
    </Layout>
  );
};

export default ExhibitionTemplate;
